import type { FC } from 'react';
import React, { useMemo } from 'react';
import { useFormat } from 'helpers/hooks/useFormat';
import usePath from 'helpers/hooks/usePath';
import Redirect from 'helpers/redirect';
import { useAccount } from 'frontastic';
import AccountFieldsForm, { AccountFieldsFormType } from '../../account/account-atoms/account-fields-form';

interface Props {
    fieldsData: Record<string, unknown> | undefined;
    email: string;
    backToInitial: () => void;
    closeLoginModal?: () => void;
}

const RegisterForm: FC<Props> = ({ fieldsData, email, backToInitial, closeLoginModal }) => {
    const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });

    const pageTitle = fieldsData
        ? formatAccountMessage({ id: 'almost.there', defaultMessage: 'Almost there...' })
        : formatAccountMessage({ id: 'become.member', defaultMessage: 'Create account' });

    const title = formatAccountMessage({ id: 'personal.details', defaultMessage: 'Personal details' });

    //account actions
    const { loggedIn } = useAccount();
    const { pathWithoutQuery } = usePath();

    //form submission

    const redirectLink = useMemo(() => {
        const lastVisitedPage = pathWithoutQuery === '/' ? '/' : '/account';

        return lastVisitedPage;
    }, [pathWithoutQuery]);

    if (loggedIn) return <Redirect target={redirectLink} />;

    return (
        <>
            <h1 className="text-18">{pageTitle}</h1>
            {!!fieldsData && (
                <div className="border-checkout-border my-18 border-b pb-20">
                    <p className="mb-4 text-14">
                        {formatAccountMessage({
                            id: 'profile.complete.description.line1',
                            defaultMessage: 'Your profile is currently incomplete.'
                        })}
                    </p>
                    <p className="text-14">
                        {formatAccountMessage({
                            id: 'profile.complete.description.line2',
                            defaultMessage: 'Add missing details to complete your order.'
                        })}
                    </p>
                </div>
            )}
            <AccountFieldsForm
                type={AccountFieldsFormType.REGISTER}
                title={title}
                containerClassName="grid gap-12"
                fieldsData={fieldsData}
                email={email}
                backToInitial={backToInitial}
                closeLoginModal={closeLoginModal}
            />
        </>
    );
};

export default RegisterForm;
